export const API = {
  AUTH: "https://medicare.keysys.pl/api/auth/",
  EVENT: "https://medicare.keysys.pl/api/event/",
  DEPARTMENT: "https://medicare.keysys.pl/api/department/",
  PROJECT: "https://medicare.keysys.pl/api/project/",
  USER: "https://medicare.keysys.pl/api/user/",
  PEOPLE: "https://medicare.keysys.pl/api/people/",
  CALENDAR: "https://medicare.keysys.pl/api/people/monthtime/",
  WORKLOG: "https://medicare.keysys.pl/api/people/subordinates/day/",
  ABSENCE_CALENDAR: "https://medicare.keysys.pl/api/calendar/",
  PEOPLE_ONLINE: "https://medicare.keysys.pl/api/people/online/",
  STATISTICS: "https://medicare.keysys.pl/api/stats/",
  ROLES: "https://medicare.keysys.pl/api/group/role/",
  GROUP: "https://medicare.keysys.pl/api/group/"
};
